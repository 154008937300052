import React from "react"
import st from "../assets/styles/agreements.module.css"

const PersonalData = () => {
  return (
    <div className={st.agreements}>
      <div className={st.container}>
        <div className={st.docHeader}>
          УТВЕРЖДЕНО Приказом генерального директора Общества с ограниченной
          ответственностью «Джусисофт» от 20.08.2017 г. № 21
        </div>
        <p>
          В процессе использования Сайтов и мобильного приложения Appbonus, при
          заполнении и инициировании отправки электронных форм с вашими личными
          данными, нажмите соответствующую кнопку (например:
          «Зарегистрироваться», «Создать учетную запись», «Попробуйте
          бесплатно», «платить» и т.д.), а также при авторизации на Сайтах и в
          мобильном приложении Appbonus через свои учетные записи (учетные
          записи) в социальных сетях вы подтверждаете, что даете Компании свое
          согласие на обработку ваших персональных данных, а также соглашаетесь
          с условиями Политика обработки персональных данных, опубликованная на
          веб-сайте Компании по адресу постоянного размещения в сети Интернет:{" "}
          <a href="/personal-data">https://www.appbonus.net/personal-data</a>
        </p>
        <h2>Согласие на обработку персональных данных</h2>
        <p>
          ООО «Джусисофт», организация, зарегистрированная на территории РФ в
          соответствии с действующим законодательством: ИНН 6312098419, адрес
          местонахождения: 443011, Самарская область, г. Самара, ул.
          Ново-садовая, д. 265А, 3 этаж (далее – «Компания») предпринимает все
          необходимые меры для соблюдения конфиденциальности вашей личной
          информации (персональных данных). Компания несет ответственность за
          процесс обработки ваших персональных данных, передаваемых вами
          Компании в процессе использования вами сайтов по адресам (URL) в сети
          Интернет: http://appbonus.net, а также в рамках использования вами по
          отдельно заключаемым между вами и Компанией соглашениям/договорам
          прикладного мобильного приложения «Appbonus» (далее — ПО «Appbonus»),
          а также в рамках использования сайтом клиентских приложений ПО
          «Appbonus» для мобильных платформ («мобильных приложений»), загрузка
          которых непосредственно на мобильное устройство конечного пользователя
          возможна посредством официальных «магазинов приложений» производителей
          соответствующих мобильных платформ «Google® Play®» (play.google.com) и
          «Apple® iTunes® AppStore» (itunes.apple.com) и заключенных вами
          договоров на использование ПО «Appbonus». Настоящее согласие на
          обработку персональных данных (далее — «Согласие») распространяется
          исключительно на те персональные данные, которые обрабатываются в
          рамках использования Сайтов, ПО «Appbonus» и исполнения соглашений/
          договоров между вами и Компанией. Использование Сайтов и ПО «Appbonus»
          предполагает ваше полное согласие на обработку ваших персональных
          данных в объёме и способами, указанными в настоящем Согласии, а также
          ваше согласие с условиями Политики обработки персональных данных
          опубликованной на сайте Компании по следующему адресу постоянного
          размещения в сети Интернет:{" "}
          <a href="/personal-data">https://www.appbonus.net/personal-data</a>.
          Компания вправе в любой момент без предварительного уведомления
          изменять, дополнять или обновлять текст настоящего Согласия в той или
          иной части. В том случае, если в настоящее Согласие будут внесены
          изменения, такое Согласие подлежит размещению на Сайтах, а
          соответствующие изменения вступают в силу в течение 20 дней с момента
          официальной публикации новой редакции Согласия на Сайтах. Если в
          течение этих 20 дней вы в письменной форме не заявите отказ от
          принятия изменений, это означает ваше принятие Согласия в новой
          редакции. Компания рекомендует регулярно просматривать текст
          настоящего Согласия, чтобы вы были своевременно информированы о любых
          изменениях и датах вступления в силу таких изменений. Действующая
          редакция Согласия опубликована на постоянной основе на странице по
          адресу (URL):{" "}
          <a href="/personal-data">https://www.appbonus.net/personal-data</a>. Вы
          подтверждаете, что настоящим предоставляете согласие на обработку
          Компанией ваших персональных данных и подтверждаете, что давая такое
          согласие, вы действуете своей волей и в своем интересе. В соответствии
          с Федеральным законом Российской Федерации от 27.07.2006 г. № 152-ФЗ
          «О персональных данных», вы согласны, при необходимости, во время
          использования функциональности Сайтов, для создания учетной записи
          пользователей Сайтов с возможностью оплаты доступа к программному
          обеспечению Компании и/ или получения доступа к ПО «Appbonus»,
          предоставить следующую информацию, которая относится к вашей личности:
          Фамилия, Имя, e-mail и телефон, файлы cookie сведения о действиях
          пользователя на сайте, сведения об оборудовании пользователя, дате и
          времени сессии, а также любую другую запрашиваемую у вас в конкретном
          сценарии использования Сайтов персональную информацию в рамках
          действующего законодательства, в т.ч. с использованием метрических
          программ Яндекс.Метрика, Google Analytics, Firebas Google, Tune,
          Amplitude, Сегменто и т.д.
        </p>
        <p>
          Данное Согласие предоставляется вами исключительно в целях исполнения
          соглашений/ договоров, заключаемых с Компанией. При обработке
          персональных данных Компания не ограничена в применении способов их
          обработки. Вы выражаете согласие на осуществление со всеми указанными
          выше вашими персональными данными следующих действий: сбор,
          систематизация, накопление, хранение, уточнение (обновление или
          изменение), использование, распространение (передача третьим лицам в
          целях исполнения соглашений/ договоров, заключаемых вами с Компанией),
          обезличивание, блокирование, уничтожение, а также осуществление любых
          иных действий с вашими персональными данными в соответствии с
          действующим законодательством РФ. Обработка персональных данных может
          осуществляться с использованием средств автоматизации, а также без их
          использования (при неавтоматической обработке). Вы выражаете согласие
          на осуществление Компанией обратной связи с вами, включая: направление
          запросов и информации, которая касается использования Сайтов, ПО
          «Appbonus» и исполнения соглашений/договоров, направление уведомлений,
          включая уведомления (рассылки) о новостях и продуктах Компании,
          обработки ваших заявок, адресованных Компании. В любой момент и время,
          на безвозмездной основе, вы, как зарегистрированный пользователь
          Сайтов, вправе отказаться от получения специальных уведомлений
          (рассылок) и иной информации о продуктах и услугах Компании, используя
          специальную процедуру отказа от таких рассылок, предусмотренную
          Компанией и указанную непосредственно в тексте (теле) писем
          соответствующих рассылок. Настоящим вы признаете и подтверждаете, что
          в случае необходимости Компания вправе предоставлять ваши персональные
          данные третьим лицам для обеспечения возможности использовать ПО
          «Appbonus» для участия в организованном третьими лицами
          (организаторами) по договорам с Компанией онлайн-мероприятиях
          (вебинарах), и, одновременно, для обеспечения возможности третьего
          лица принять решение о самой возможности вашего участия в
          организованном таким третьим лицом онлайн-мероприятии (вебинаре), в ПО
          «Appbonus» встроена функция сбора персональных данных в привязке к
          конкретному онлайн-мероприятию. Согласившись с условиями
          предоставления ПО «Appbonus» и действуя своей волей и в своём
          интересе, вы выражаете согласие на то, чтобы введённые вами данные
          были направлены организатору того онлайн-мероприятия, в котором вы
          желаете принять участие, и для подключения к которому вы будете
          использовать ПО «Appbonus». Организатор данного онлайн-мероприятия,
          действуя по собственному усмотрению, без уведомления Компании, может
          направлять в ваш адрес информационные сообщения, в том числе, действуя
          в рамках договора с Компанией, использовать функции ПО «Appbonus» для
          рассылки напоминаний о конкретном мероприятии, ваше участие в котором
          санкционировано таким организатором, и/или других организуемых
          организатором с использованием ПО «Appbonus» вебинарах. Организатор
          данного онлайн-мероприятия имеет право на обработку ваших персональных
          данных на основании настоящего Согласия. Вы непосредственно после
          акцепта оферты заключить договор на использование ПО «Appbonus»
          получаете возможность загрузить клиентскую часть ПО «Appbonus» на
          мобильное устройство-клиент мобильного приложения. Дальнейшая работа с
          ПО «Appbonus» производится в диалоговом режиме. Вы используете ПО
          «Appbonus» на условиях «открытой лицензии» (ст. 1286.1 ГК РФ), что
          является безвозмездным и не противоречит ст. 1235 ГК РФ. Также вы
          признаете и подтверждаете, что в случае необходимости Компания вправе
          предоставлять ваши персональные данные третьим лицам в целях оказания
          вам услуг технической поддержки по вопросам использования Сайтов и ПО
          «Appbonus», а также (в обезличенном виде) в статистических,
          маркетинговых и иных научных целях. Такие третьи лица имеют право на
          обработку ваших персональных данных на основании настоящего Согласия.
          Для получения более подробной информации о процессе обработки
          Компанией ваших персональных данных, по вопросам исправления,
          блокировки или удаления любых ваших персональных данных, получении
          доступа к вашим персональным данным, отмене вашего согласия на
          определенные виды обработки персональных данных, удаления вашего
          адреса электронной почты из баз данных, формируемых при работе Сайтов
          и Плеера трансляции, а также для направления вопросов, замечаний или
          предложений, касающихся настоящего Согласия, вы можете связываться с
          Компанией по следующему адресу электронной почты mail@appbonus.net или
          направить письмо по адресу: ООО «Джусисофт», 443011, Самарская
          область, г. Самара, ул. Ново-садовая, д. 265А, 3 этаж. Вы
          предоставляете согласие на обработку ваших персональных данных в
          течение срока действия заключенных между вами и Компанией договоров и
          соглашений и в течение 1 (одного) года после расторжения таких
          договоров как в связи с окончанием срока их действия, так и досрочно,
          однако вы можете отозвать такое согласие посредством письменного
          уведомления Компании не менее чем за 1 (один) месяц до момента отзыва
          согласия, при этом вы осознаете, что, отзывая такое согласие, вы
          обязаны инициировать процедуру досрочного расторжения договоров с
          Компанией. В процессе использования Сайтов и ПО «Appbonus», при
          заполнении и инициации отправки электронных форм с вашими
          персональными данными, нажимая соответствующую кнопку (например:
          «Зарегистрироваться», «Создать аккаунт», «Попробовать бесплатно»,
          «оплатить» и т.п.), а также при авторизации на Сайтах и ПО «Appbonus»
          посредством ваших аккаунтов (учетных записей) в социальных сетях, вы
          подтверждаете, что предоставляете Компании свое согласие на обработку
          ваших персональных данных.
        </p>
      </div>
    </div>
  )
}

export default PersonalData
